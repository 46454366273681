import { removeAffiliateIdCookies } from '@jobmojito/app/utils/cookies/useAffiliateCookieCleanup'
import { updatePostHogPersistence } from '@jobmojito/components/providers/posthog'
import { useRouter } from 'next/navigation'
import { useEffect } from 'react'
import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

const useCookieConsent = (isUseLocale: boolean = true) => {
  const router = useRouter()
  let t: (key: string) => string
  // TODO - add i18n support for all project
  if (isUseLocale) {
    // Import useTranslations only if localization is used
    const { useTranslations } = require('next-intl')
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const translations = useTranslations('component-cookie-consent')
    t = translations
  } else {
    // Use static JSON en file for translation in case of absence of localization
    const data = require('@jobmojito/components/messages/package-en.json')
    t = (key: string) => data['component-cookie-consent'][key]
  }

  useEffect(() => {
    const domain = window.location.hostname
    const domainParts = domain.split('.')
    let mainDomain = `.${domain}`

    if (domainParts.length >= 2) {
      mainDomain = `.${domainParts.join('.')}`
    }

    const baseUrl = `${window.location.protocol}//${window.location.hostname}`
    const cookiePolicyUrl = `${baseUrl}/cookie_policy`

    CookieConsent.run({
      onFirstConsent: ({ cookie }) => {
        const analyticsDenied = !cookie.categories?.includes('analytics')
        updatePostHogPersistence(analyticsDenied)

        const marketingDenied = !cookie.categories?.includes('marketing')
        if (marketingDenied) {
          removeAffiliateIdCookies()
        }
      },
      onChange: ({ cookie, changedCategories }) => {
        const cookiesCategories = cookie.categories
        const analyticsDenied = !cookiesCategories.includes('analytics')
        updatePostHogPersistence(analyticsDenied)

        const marketingDenied = !cookiesCategories.includes('marketing')
        if (marketingDenied) {
          removeAffiliateIdCookies()
        }
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functionality: { enabled: true, readOnly: true },
        analytics: {},
        marketing: {},
      },
      cookie: {
        domain: mainDomain, // Set cookies for all subdomains
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: t('Hello traveller, it is cookie time!'),
              description: t(
                'You can customize your cookie experience in this modal window',
              ),
              acceptAllBtn: t('Accept all'),
              acceptNecessaryBtn: t('Reject all'),
              showPreferencesBtn: t('Manage Individual preferences'),
              footer: `<a href="${cookiePolicyUrl}">${t('Cookie policy')}</a>`,
            },
            preferencesModal: {
              title: t('Cookie setup'),
              acceptAllBtn: t('Accept all'),
              acceptNecessaryBtn: t('Reject all'),
              savePreferencesBtn: t('Accept current selection'),
              closeIconLabel: t('Close modal'),
              sections: [
                {
                  title: '',
                  description: t(
                    'In accordance with the cookies you consent to be used on this screen, relevant cookies are stored on your browser or device',
                  ),
                },
                {
                  title: t('Technical Cookies'),
                  description: t(
                    'These cookies are strictly necessary to provide you with services available through our Job Mojito service and to use some of its features, such as access to secure areas',
                  ),
                  linkedCategory: 'necessary',
                },
                {
                  title: t('Functionality Cookies'),
                  description: t(
                    'These cookies remember your usage preferences to enable the website to work according to your preferences',
                  ),
                  linkedCategory: 'functionality',
                },
                {
                  title: t('Analytics Cookies'),
                  description: t(
                    'These cookies collect information about how users use the website',
                  ),
                  linkedCategory: 'analytics',
                },
                {
                  title: t('Marketing Cookies'),
                  description: t(
                    'These cookies are used to present advertisements/campaigns according to the interests of users and to measure the effectiveness of marketing activities',
                  ),
                  linkedCategory: 'marketing',
                },
              ],
            },
          },
        },
      },
    })
  }, [t])
}

export default useCookieConsent
